






import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  components: { ORequestCreateForm: () => import('@/components/organisms/o-request-create-form.vue') }
})
